import {
  usePeriodsPageQuery,
  useTriageInsightsMetricsQuery,
} from "@comulate/graphql-types";
import { useMemo, useState } from "react";
import { useLoggedInUser } from "../../components/LoggedInUserProvider";
import { DateTime } from "luxon";

export default function useTriageInsightsData() {
  const { organization } = useLoggedInUser();

  const { data: periodsData } = usePeriodsPageQuery();

  const isDemoOrganization = organization?.flags.isDemo ?? false;
  const latestOpenPeriod = periodsData?.periods.find(
    (period) => period.status === "OPEN"
  );

  const [today] = useState<DateTime>(DateTime.now());

  const endDate =
    isDemoOrganization && latestOpenPeriod
      ? DateTime.fromISO(latestOpenPeriod.rangeStart, { zone: "utc" }).plus({
          days: 30,
        })
      : today;

  const {
    data: triageInsights,
    loading: loadingTriageInsights,
    previousData: previousTriageInsights,
  } = useTriageInsightsMetricsQuery({
    variables: {
      startDate: endDate.minus({ days: 30 }).startOf("day").toISO(),
      endDate: endDate.startOf("day").toISO(),
    },
  });

  const unappliedRevenue = useFormattedData(
    (triageInsights ?? previousTriageInsights)?.triageUnappliedRevenueMetrics ??
      [],
    "revenue"
  );

  const withoutCoverage = useFormattedData(
    (triageInsights ?? previousTriageInsights)?.triageWithoutCoverageMetrics ??
      [],
    "revenue"
  );

  return {
    loadingTriageInsights: loadingTriageInsights && !triageInsights,
    unappliedRevenue,
    withoutCoverage,
  };
}

function filterZeros(data: { date: string; value: number }[]) {
  // If ALL values are zero, return an empty array
  if (data.every((d) => d.value === 0)) {
    return [];
  }

  return data;
}

// Hook which will transforms raw data e.g.
// [{ date: '2021-09-01', revenue: 100 }, { date: '2021-09-02', revenue: 110 }]
// into formatted data
// [{ date: '2021-09-01', value: 100 }, delta: .1, lastValue: 110]
function useFormattedData<T extends { date: string }[]>(
  data: T,
  valueKey: string
) {
  return useMemo(() => extractFormattedData(data, valueKey), [data, valueKey]);
}

// Given a list of raw data, formats it to get relevant/correct values
function extractFormattedData<T extends { date: string }[]>(
  data: T,
  key: string
): {
  data: { date: string; value: number }[];
  delta: number | null;
  latestValue: number;
} {
  const formattedData = data.map((d) => ({ date: d.date, value: d[key] }));
  return {
    data: filterZeros(formattedData),
    delta: getDeltaPercentage(formattedData),
    latestValue: getLastValue(formattedData),
  };
}

/** A number which should be between 0 - 1 and represents the delta over the
 * range of date data */
function getDeltaPercentage(data: { date: string; value: number }[]) {
  if (data.length === 0) {
    return 0;
  }

  const lastValue = data[data.length - 1].value;
  const firstValue = data[0].value;

  if (firstValue === 0) {
    return null;
  }

  return (lastValue - firstValue) / firstValue;
}

/** Represents the most recent value in a list of data */
function getLastValue(data: { date: string; value: number }[]) {
  if (data.length === 0) {
    return 0;
  }

  return data[data.length - 1].value;
}
