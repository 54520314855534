import { formatDollarsWithoutCents } from "../../src/formatting";
import SectionedRow from "./SectionedRow";
import SparkLineChartSection from "./SparkLineChartSection";
import useTriageInsightsData from "./useTriageInsightsData";

export default function TriageInsightsSection() {
  const { loadingTriageInsights, unappliedRevenue, withoutCoverage } =
    useTriageInsightsData();

  return (
    <>
      <h2 className="text-2xl leading-8 font-medium mt-5 text-zinc-900 py-5">
        Triage Insights
      </h2>
      <SectionedRow showDivider={true}>
        <SparkLineChartSection
          label="Unapplied Revenue"
          data={unappliedRevenue.data}
          displayValue={formatDollarsWithoutCents(unappliedRevenue.latestValue)}
          delta={unappliedRevenue.delta}
          goodDelta="negative"
          loading={loadingTriageInsights}
          mode="dollars"
        />
        <SparkLineChartSection
          data={withoutCoverage.data}
          label="Without Coverage"
          displayValue={formatDollarsWithoutCents(withoutCoverage.latestValue)}
          delta={withoutCoverage.delta}
          goodDelta="negative"
          loading={loadingTriageInsights}
          mode="dollars"
        />
      </SectionedRow>
    </>
  );
}
