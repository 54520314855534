import Skeleton from "react-loading-skeleton";
import { formatDollarsWithoutCents } from "../../src/formatting";
import { BarList } from "../../components/BarList";

type StackedBarSectionProps = {
  loading: boolean;
  label: string;
  data: {
    label: string;
    value: number;
    tagColor?: string;
  }[];
  mode: "Revenue" | "Transactions";
};

export default function StackedBarSection({
  loading,
  label,
  data,
  mode,
}: StackedBarSectionProps) {
  const formattedData = data.map((item) => ({
    name: item.label,
    value: item.value,
    tagColor: item.tagColor,
  }));

  if (loading) {
    return (
      <div className="w-full h-full pt-5">
        <div className="text-base leading-6 font-medium text-zinc-600">
          {label}
        </div>
        <div className="mt-4">
          <Skeleton height={32} className="w-full" />
          <Skeleton height={32} className="mt-3 w-3/4" />
          <Skeleton height={32} className="mt-3 w-1/4" />
        </div>
      </div>
    );
  }

  if (!data || data.length === 0) {
    return null;
  }

  return (
    <div className="w-full flex flex-col pt-5 relative">
      <div className="text-base leading-6 font-medium text-zinc-600">
        {label}
      </div>
      <div className="mt-3 overflow-y-scroll relative max-h-[302px]">
        <BarList
          data={formattedData}
          valueFormatter={
            mode === "Revenue"
              ? formatDollarsWithoutCents
              : (value) => value.toLocaleString()
          }
          color="bg-sand-100 text-zinc-900"
          className="h-full"
        />
      </div>
    </div>
  );
}
