import { useEffect, useMemo, useState } from "react";
import { Option } from "../../components/Select";
import useImportPeriod from "../../hooks/useImportPeriod";
import { PeriodStatus, usePeriodsPageQuery } from "@comulate/graphql-types";
import { getPeriodName } from "../../src/formatting";

export type PeriodOption = Option & { status: PeriodStatus };

/**
 * Hook to manage the period options state selection in the dashboard
 */
export default function usePeriodOptions() {
  const [selectedPeriod, setSelectedPeriod] = useState<PeriodOption | null>(
    null
  );

  const { data: periodsData } = usePeriodsPageQuery();
  const { importPeriod } = useImportPeriod();

  const allPeriodsOptions: PeriodOption[] = useMemo(() => {
    return (
      periodsData?.periods
        .map((period) => ({ ...period, value: getPeriodName(period) }))
        .sort((a, b) => (a.rangeStart > b.rangeStart ? -1 : 1)) ?? []
    );
  }, [periodsData]);

  useEffect(() => {
    if (selectedPeriod === null && importPeriod) {
      const userSelectedPeriod = allPeriodsOptions.find(
        (option) => option.id === importPeriod.id
      );

      // Prioritize user-selected period
      if (userSelectedPeriod) {
        setSelectedPeriod(userSelectedPeriod);
      }

      // Otherwise, default to first open period
      setSelectedPeriod(
        allPeriodsOptions.find(
          (option) => option.status === PeriodStatus.OPEN
        ) || null
      );
    }
  }, [allPeriodsOptions, importPeriod, selectedPeriod]);

  return [selectedPeriod, setSelectedPeriod, allPeriodsOptions] as const;
}
