import {
  DashboardDbMetricsQueryResult,
  DirectBillTeamMetricsInstance,
  useDashboardDbMetricsQuery,
} from "@comulate/graphql-types";
import useFeatureFlag, {
  FeatureFlag,
} from "../../feature-flags/useFeatureFlag";
import DonutChartSection from "./DonutChartSection";
import SectionedRow from "./SectionedRow";
import TabbedSection from "./TabbedSection";
import { ReactNode, useEffect, useState } from "react";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { useUserAvatarUrls } from "../../hooks/useUserAvatarUrls";
import { isNullOrUndefined } from "@comulate/file-utils";
import { classNames, formatDollarsWithoutCents } from "../../src/formatting";
import ArrowCurvedRightIcon20 from "../../components/icons/ArrowCurvedRightIcon20";
import Skeleton from "react-loading-skeleton";
import VolumeChart from "./VolumeChart";
import _ from "lodash";

type DirectBillSectionProps = {
  selectedPeriodId: string | null;
};

export default function DirectBillSection({
  selectedPeriodId,
}: DirectBillSectionProps) {
  const showOwlSection = useFeatureFlag<boolean>(FeatureFlag.OWL) ?? false;

  const { data } = useDashboardDbMetricsQuery({
    variables: {
      periodId: selectedPeriodId ?? "",
    },
    skip: !selectedPeriodId,
  });

  // Deposits section tab state

  const {
    localStorageState: depositsLocalStorageState,
    setLocalStorageState: setDepositsLocalStorageState,
  } = useLocalStorage<"Revenue" | "Count">("dashboard-direct-bill-deposits");

  const [depositsSectionTab, setDepositsSectionTab] = useState<
    "Revenue" | "Count"
  >(depositsLocalStorageState || "Revenue");

  useEffect(() => {
    setDepositsLocalStorageState(depositsSectionTab);
  }, [depositsSectionTab, setDepositsLocalStorageState]);

  // Production section tab state

  const {
    localStorageState: productionLocalStorageState,
    setLocalStorageState: setProductionLocalStorageState,
  } = useLocalStorage<"Revenue" | "Transactions">(
    "dashboard-direct-bill-production"
  );

  const [productionSectionTab, setProductionSectionTab] = useState<
    "Revenue" | "Transactions"
  >(productionLocalStorageState || "Revenue");

  useEffect(() => {
    setProductionLocalStorageState(productionSectionTab);
  }, [productionSectionTab, setProductionLocalStorageState]);

  return (
    <div className="flex flex-col">
      <SectionedRow className="h-[360px]">
        {showOwlSection && (
          <TabbedSection<"Revenue" | "Count">
            label="Deposits"
            selectedTabOption={depositsSectionTab}
            onTabClick={setDepositsSectionTab}
            tabOptions={["Revenue", "Count"]}
          >
            <DonutChartSection
              loading={!data}
              data={[
                {
                  label: "Fully applied",
                  color: "green-500", // green
                  value:
                    (depositsSectionTab === "Revenue"
                      ? data?.dashboardOwlMetricsSummary.fullyApplied.revenue
                      : data?.dashboardOwlMetricsSummary.fullyApplied
                          .transactions) ?? 0,
                },
                {
                  label: "Partially applied",
                  color: "yellow-500", // yellow
                  value:
                    (depositsSectionTab === "Revenue"
                      ? data?.dashboardOwlMetricsSummary.partiallyApplied
                          .revenue
                      : data?.dashboardOwlMetricsSummary.partiallyApplied
                          .transactions) ?? 0,
                },
                {
                  label: "Unapplied",
                  color: "orange-500", // orange
                  value:
                    (depositsSectionTab === "Revenue"
                      ? data?.dashboardOwlMetricsSummary.unapplied.revenue
                      : data?.dashboardOwlMetricsSummary.unapplied
                          .transactions) ?? 0,
                },
              ]}
              displayMode={
                depositsSectionTab === "Revenue" ? "dollars" : "count"
              }
              colors={["green-500", "yellow-500", "orange-500"]}
            />
          </TabbedSection>
        )}
        <TabbedSection<"Revenue" | "Transactions">
          label="Production"
          selectedTabOption={productionSectionTab}
          onTabClick={setProductionSectionTab}
          tabOptions={["Revenue", "Transactions"]}
        >
          <DonutChartSection
            loading={!data}
            data={[
              {
                label: "Posted",
                color: "blue-500",
                value:
                  (productionSectionTab === "Revenue"
                    ? data?.dashboardDirectBillVolumeMetrics.posted.revenue
                    : data?.dashboardDirectBillVolumeMetrics.posted
                        .transactions) ?? 0,
              },
              {
                label: "Ready to post",
                color: "green-500",
                value:
                  (productionSectionTab === "Revenue"
                    ? data?.dashboardDirectBillVolumeMetrics.readyToPost.revenue
                    : data?.dashboardDirectBillVolumeMetrics.readyToPost
                        .transactions) ?? 0,
              },
              {
                label: "Unreconciled",
                color: "orange-500",
                value:
                  (productionSectionTab === "Revenue"
                    ? data?.dashboardDirectBillVolumeMetrics.unreconciled
                        .revenue
                    : data?.dashboardDirectBillVolumeMetrics.unreconciled
                        .transactions) ?? 0,
              },
            ]}
            displayMode={
              productionSectionTab === "Revenue" ? "dollars" : "count"
            }
            colors={["blue-500", "green-500", "orange-500"]}
          />
        </TabbedSection>
        <TeamSection metrics={data?.dashboardDirectBillTeamMetrics} />
      </SectionedRow>
      <VolumeChart
        thisPeriod={data?.periodDirectBillRevenueSummary.thisPeriodRevenue}
        lastPeriod={data?.periodDirectBillRevenueSummary.lastPeriodRevenue}
      />
    </div>
  );
}

const TeamSection = ({
  metrics,
}: {
  metrics?: NonNullable<
    DashboardDbMetricsQueryResult["data"]
  >["dashboardDirectBillTeamMetrics"];
}) => {
  const { avatarUrlForUserId } = useUserAvatarUrls();

  return (
    <BaseSection
      label="Team"
      metrics={_.range(metrics?.length ?? 5).map((ind) => {
        const value = metrics?.[ind];

        return {
          level: 0,
          text: value ? (
            <div className="flex items-center space-x-2">
              <img
                className="h-6 w-6 rounded-full self-center"
                src={avatarUrlForUserId(value.user.id)}
              />
              <div className="truncate" title={value.user.fullName}>
                {value.user.fullName}
              </div>
            </div>
          ) : null,
          value,
        };
      })}
      tabOptions={[
        {
          id: "revenue",
          value: "Revenue",
        },
        {
          id: "statementCount",
          value: "Statements",
        },
        {
          id: "reconciliationCount",
          value: "Reconciliations",
        },
      ]}
    />
  );
};

const BaseSection = ({
  label,
  metrics,
  tabOptions,
}: {
  label: string;
  metrics?: {
    level?: number;
    text: string | ReactNode;
    value: Omit<DirectBillTeamMetricsInstance, "user"> | undefined;
  }[];
  tabOptions: {
    id: keyof DirectBillTeamMetricsInstance;
    value: string;
  }[];
}) => {
  const storageKey = `dashboard-direct-bill-${label
    .toLowerCase()
    .replaceAll(" ", "-")}`;
  const { localStorageState, setLocalStorageState } =
    useLocalStorage<keyof DirectBillTeamMetricsInstance>(storageKey);

  const [selectedTab, setSelectedTab] = useState(
    tabOptions.find((option) => option.id === localStorageState) ||
      tabOptions[0]
  );

  useEffect(() => {
    setLocalStorageState(selectedTab.id);
  }, [selectedTab.id, setLocalStorageState]);

  const volumeMetric = selectedTab.id;
  const sortedMetrics = metrics?.sort(
    (a, b) =>
      parseFloat(b?.value?.[volumeMetric] || 0) -
      parseFloat(a?.value?.[volumeMetric] || 0)
  );
  const filteredMetrics = sortedMetrics?.filter(
    ({ value }) =>
      isNullOrUndefined(value?.[volumeMetric]) || value?.[volumeMetric]
  );

  return (
    <div className="pt-5 flex-1">
      <div className="text-lg leading-6 font-medium">{label}</div>
      <div className="divide-y space-y-3 mt-3">
        <div className="flex space-x-1">
          {tabOptions.map((option) => (
            <div
              key={option.id as string}
              className={classNames(
                "px-1 py-0.5 rounded text-sm cursor-pointer font-medium",
                option.id === selectedTab.id
                  ? "text-zinc-900 bg-zinc-100"
                  : "text-zinc-500"
              )}
              onClick={() => setSelectedTab(option)}
            >
              {option.value}
            </div>
          ))}
        </div>
        <div className="overflow-y-auto h-[264px]">
          {filteredMetrics?.map(({ level = 0, text, value }, metricInd) => (
            <div
              key={metricInd}
              className={classNames(
                "flex justify-between items-center w-full space-x-2",
                (filteredMetrics[metricInd + 1]?.level || Infinity) < level &&
                  "border-b"
              )}
            >
              {level > 0 && (
                <ArrowCurvedRightIcon20
                  className={classNames(
                    "text-zinc-500 shrink-0",
                    level === 1 ? "ml-2" : "ml-9"
                  )}
                />
              )}
              <div
                className={classNames(
                  "flex justify-between items-center py-3 w-full min-w-0",
                  metricInd !== filteredMetrics?.length - 1 &&
                    (filteredMetrics[metricInd + 1]?.level || Infinity) >=
                      level &&
                    "border-b"
                )}
              >
                <div className="text-sm leading-6 font-medium text-zinc-600 truncate">
                  {text}
                </div>
                <div className="text-xl font-normal tabular-nums text-zinc-900 truncate">
                  {isNullOrUndefined(value) ? (
                    <Skeleton height={14} width={120} />
                  ) : volumeMetric === "revenue" ? (
                    formatDollarsWithoutCents(value?.[volumeMetric])
                  ) : (
                    value?.[volumeMetric].toLocaleString()
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
