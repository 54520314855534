import Head from "next/head";
import Dashboard from "../apps/Dashboard";
import { PageWithLayout } from "./_app";

const HomePage: PageWithLayout = () => (
  <>
    <Head>
      <title>Dashboard</title>
    </Head>
    <Dashboard />
  </>
);

HomePage.getLayout = ({ children }: { children: React.ReactNode }) => {
  return <div className="w-full h-full">{children}</div>;
};

export default HomePage;
