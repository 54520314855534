import React from "react";
import { AreaChart } from "@tremor/react";
import { DateTime } from "luxon";
import Skeleton from "react-loading-skeleton";
import {
  DateFormat,
  formatDate,
  formatDollarsWithoutCents,
  isNotNullAndNotUndefined,
  isNullOrUndefined,
} from "../../src/formatting";
import {
  DashboardDbMetricsQuery,
  RevenueInPeriod,
} from "../../src/generated/graphql";

export default function VolumeChart({
  thisPeriod,
  lastPeriod,
}: {
  thisPeriod?: DashboardDbMetricsQuery["periodDirectBillRevenueSummary"]["thisPeriodRevenue"];
  lastPeriod: DashboardDbMetricsQuery["periodDirectBillRevenueSummary"]["lastPeriodRevenue"];
}) {
  return (
    <div className="border-b pb-5">
      <div className="flex space-x-4 items-center">
        <div className="text-lg leading-6 font-medium py-6">
          Production by Day
        </div>
        <div className="flex items-center space-x-4">
          <div className="flex items-center space-x-2">
            <div className="bg-green-600 w-3 h-3 rounded-sm" />
            <div className="text-xs leading-none font-normal text-zinc-600">
              This Month
            </div>
          </div>
          <div className="flex items-center space-x-2">
            <div className="bg-sand-500 w-3 h-3 rounded-sm" />
            <div className="text-xs leading-none font-normal text-zinc-600">
              Last Month
            </div>
          </div>
        </div>
      </div>
      {isNotNullAndNotUndefined(thisPeriod) ? (
        <Chart thisPeriod={thisPeriod} lastPeriod={lastPeriod} />
      ) : (
        <div className="flex flex-grow justify-center items-center h-60">
          <div className="flex flex-shrink items-end space-x-3">
            <Skeleton width="20px" height="60px" />
            <Skeleton width="20px" height="90px" />
            <Skeleton width="20px" height="120px" />
            <Skeleton width="20px" height="150px" />
          </div>
        </div>
      )}
    </div>
  );
}

const pointsFromRevenueByDate = (
  revenueInPeriod: RevenueInPeriod,
  fullMonth = false
) => {
  const periodStartDate = DateTime.fromISO(revenueInPeriod.periodStartDate, {
    zone: "utc",
  });
  const periodMonthStart = periodStartDate.startOf("month");
  const endDate = fullMonth
    ? periodStartDate.endOf("month")
    : DateTime.min(DateTime.utc(), periodStartDate.endOf("month"));
  let iteratedDate = periodMonthStart;
  const dates: DateTime[] = [];
  while (iteratedDate <= endDate) {
    dates.push(iteratedDate);
    iteratedDate = iteratedDate.plus({ days: 1 });
  }

  const parsedRevenueByDate = revenueInPeriod.revenueByDate.map(
    ({ revenue, date }) => ({
      revenue,
      date: DateTime.fromISO(date),
    })
  );

  return dates.map((date) => {
    const revenueSum = parsedRevenueByDate
      .filter((dateRev) => dateRev.date <= date)
      .reduce((sum, { revenue }) => sum + revenue, 0);
    return { date, revenue: revenueSum };
  });
};

const prepareChartData = (
  thisPeriod: RevenueInPeriod | undefined | null,
  lastPeriod: RevenueInPeriod | undefined | null
) => {
  if (isNullOrUndefined(thisPeriod) || isNullOrUndefined(lastPeriod)) {
    return [];
  }

  const thisMonthPoints = pointsFromRevenueByDate(thisPeriod, true);
  const lastMonthPoints = pointsFromRevenueByDate(lastPeriod);

  return thisMonthPoints.map((point, index) => ({
    date: formatDate(point.date, DateFormat.DATE_NAME_SHORT),
    "This Month":
      index < DateTime.utc().day || point.date < DateTime.utc()
        ? point.revenue
        : null,
    "Last Month": lastMonthPoints[index]
      ? lastMonthPoints[index].revenue
      : null,
  }));
};

function Chart({
  thisPeriod,
  lastPeriod,
}: {
  thisPeriod: DashboardDbMetricsQuery["periodDirectBillRevenueSummary"]["thisPeriodRevenue"];
  lastPeriod: DashboardDbMetricsQuery["periodDirectBillRevenueSummary"]["lastPeriodRevenue"];
}) {
  const chartData = prepareChartData(thisPeriod, lastPeriod);

  return (
    <AreaChart
      className="h-60"
      data={chartData}
      index="date"
      categories={["This Month", "Last Month"]}
      colors={["green-600", "#af916a"]}
      valueFormatter={formatDollarsWithoutCents}
      yAxisWidth={60}
      showLegend={false}
      showYAxis={false}
      intervalType="equidistantPreserveStart"
      showXAxis={true}
      showGridLines={true}
      startEndOnly={true}
      showAnimation={true}
      showTooltip={true}
      autoMinValue={true}
      connectNulls={true}
    />
  );
}
