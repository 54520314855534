import clsx from "clsx";
import { useEffect, useState } from "react";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import SectionedRow from "./SectionedRow";
import StackedBarSection from "./StackedBarSection";
import useTriageDetailAnalyticsData from "./useTriageDetailAnalyticsData";

export default function TriageDetailSection() {
  // Triage detail tab state

  const {
    localStorageState: triageDetailLocalStorageState,
    setLocalStorageState: setTriageDetailLocalStorageState,
  } = useLocalStorage<"Revenue" | "Transactions">(
    "dashboard-direct-bill-triage-detail"
  );

  const [triageDetailTab, setTriageDetailTab] = useState<
    "Revenue" | "Transactions"
  >(triageDetailLocalStorageState || "Revenue");

  useEffect(() => {
    setTriageDetailLocalStorageState(triageDetailTab);
  }, [triageDetailTab, setTriageDetailLocalStorageState]);

  const { loadingTriageDetailAnalytics, byCarrier, byStructure, byReason } =
    useTriageDetailAnalyticsData(triageDetailTab);

  return (
    <>
      <div className="border-zinc-200 pt-5 pb-3 flex flex-col space-y-3">
        <h3 className="text-lg leading-6 font-medium text-zinc-900">
          Triage Detail
        </h3>
        <div className="flex flex-row items-center justify-between w-full">
          <div className="flex flex-row items-center space-x-1">
            <div
              onClick={() => setTriageDetailTab("Revenue")}
              className={clsx(
                "px-1 py-0.5 rounded text-sm cursor-pointer font-medium",
                triageDetailTab === "Revenue"
                  ? "text-zinc-900 bg-zinc-100"
                  : "text-zinc-500"
              )}
            >
              Revenue
            </div>
            <div
              onClick={() => setTriageDetailTab("Transactions")}
              className={clsx(
                "px-1 py-0.5 rounded text-sm cursor-pointer font-medium",
                triageDetailTab === "Transactions"
                  ? "text-zinc-900 bg-zinc-100"
                  : "text-zinc-500"
              )}
            >
              Transactions
            </div>
          </div>
          {/* This filter pill will crash if you click it. Once we
                  decide which options are available here while populating we,
                  will make this into a select. */}
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-ignore */}
          {/* <FilterPill filterName="Status" /> */}
        </div>
      </div>
      <div className="h-[360px]">
        <SectionedRow>
          <StackedBarSection
            label="Triage by Carrier"
            data={byCarrier}
            loading={loadingTriageDetailAnalytics}
            mode={triageDetailTab}
          />
          <StackedBarSection
            label="Triage by Structure"
            data={byStructure}
            loading={loadingTriageDetailAnalytics}
            mode={triageDetailTab}
          />
          <StackedBarSection
            label="Triage by Reason"
            data={byReason}
            loading={loadingTriageDetailAnalytics}
            mode={triageDetailTab}
          />
        </SectionedRow>
      </div>
    </>
  );
}
