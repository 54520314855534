import { clsx } from "clsx";

type SectionedRowProps = {
  children: React.ReactNode;
  // Decides whether to show a divider between sections in the row
  showDivider?: boolean;
  className?: string;
};

/**
 * A row of sections on Dashboard, just pass the children as each of the
 * sections you want and will handle the padding and spacing bars on top and bottom, etc.
 */
export default function SectionedRow({
  children,
  className,
  showDivider = true,
}: SectionedRowProps) {
  return (
    <div
      className={clsx(
        `flex flex-row flex-grow justify-center items-start border-y
        border-zinc-200 [&>*]:h-full [&>*]:w-full [&>*]:px-5
        [&>*:first-child]:pl-0 [&>*:last-child]:pr-0 relative h-full`,
        showDivider && "divide-x",
        className
      )}
    >
      {children}
    </div>
  );
}
