import { clsx } from "clsx";

type TabbedSectionProps<T> = {
  label: string;
  onTabClick: (tab: T) => void;
  selectedTabOption: T extends string ? T : never;
  tabOptions: T[];
  children?: React.ReactNode;
};

export default function TabbedSection<T extends string>({
  label,
  children,
  selectedTabOption,
  onTabClick,
  tabOptions,
}: TabbedSectionProps<T>) {
  return (
    <div className="h-full pt-5 flex-1">
      <h3 className="text-lg leading-6 font-medium">{label}</h3>
      <div className="flex flex-row items-center space-x-1 w-min mt-3">
        {tabOptions.map((tabOption) => {
          const isSelected = tabOption === selectedTabOption;
          return (
            <div
              key={tabOption}
              className={clsx(
                "px-1 py-0.5 rounded text-sm cursor-pointer font-medium",
                isSelected ? "text-zinc-900 bg-zinc-100" : "text-zinc-500"
              )}
              onClick={() => onTabClick(tabOption)}
            >
              {tabOption}
            </div>
          );
        })}
      </div>
      <hr className="w-full my-3 border-zinc-200" />
      <div>{children}</div>
    </div>
  );
}
