import * as React from "react";
import type { SVGProps } from "react";

const ArrowCurvedRightIcon20 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      d="m17.942 12.317-3.75 3.75a.625.625 0 0 1-.884-.884L15.99 12.5H10a8.133 8.133 0 0 1-8.125-8.125.625.625 0 0 1 1.25 0A6.883 6.883 0 0 0 10 11.25h5.991l-2.683-2.683a.625.625 0 0 1 .884-.884l3.75 3.75a.627.627 0 0 1 0 .884"
    />
  </svg>
);

export default ArrowCurvedRightIcon20;
