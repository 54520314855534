import { LineChart } from "@tremor/react";
import { useMemo } from "react";
import { clsx } from "clsx";
import {
  DateFormat,
  formatDate,
  formatDeltaPercent,
  formatDollarsWithoutCents,
} from "../../src/formatting";
import Skeleton from "react-loading-skeleton";
import ms from "ms";
import { DateTime } from "luxon";
import {
  isNotNullAndNotUndefined,
  isNullOrUndefined,
} from "@comulate/file-utils";

type SparkLineChartSectionProps = {
  loading: boolean;
  data: {
    date: string;
    value: number;
  }[];
  label: string;
  displayValue: string;
  /**
   * A number between [-1:1] which represents the change in value. It is
   * displayed as a red or green +-XX.X% next to the label of the section.
   *
   * If delta is null, we skip displaying a delta.
   * */
  delta: number | null;
  mode: "dollars" | "time";
  goodDelta: "positive" | "negative";
};

// If this component needs to be extended, it is a prime candidate for moving
// to a better model of composition to manage the complexity. For example, if
// you want to tweak this for also working for a bar chart for example, first
// talk to @gavinfogel about how best to proceed.
//
// Since the uses are not yet clear, it is not worth trying to pull this out
// into composable pieces yet
export default function SparkLineChartSection({
  loading,
  data,
  label,
  displayValue,
  delta,
  mode,
  goodDelta,
}: SparkLineChartSectionProps) {
  const formattedPercentage = isNullOrUndefined(delta)
    ? delta
    : formatDeltaPercent(delta);

  const badgeColorScheme = useMemo(
    () => makePercentDeltaStyles(delta ?? 0, goodDelta),
    [delta, goodDelta]
  );

  const labeled = data.map((d) => ({
    ...d,
    [label]: d.value,
    date: formatDate(
      DateTime.fromISO(d.date, {
        zone: "utc",
      }),
      DateFormat.DATE_NAME_SHORT
    ),
  }));

  // Loading skeleton of the section, the height in this is set meticulously to
  // match the height of the section when it is loaded, so that the page doesn't
  // jump around when the data is loaded
  if (loading) {
    return (
      <div className="w-full space-y-2 mt-5">
        <div className="w-full flex flex-row items-center justify-start space-x-2">
          <Skeleton height={20} width={190} />
        </div>
        <Skeleton height={40} width={120} className="mt-2 mb-[35px]" />
      </div>
    );
  }

  // If there is no data, just return null-- we just hide the section instead of
  // showing an explicit 'no data'
  if (!data || data.length === 0) {
    return null;
  }

  return (
    <div className="w-full space-y-2 py-5">
      <div className="w-full flex flex-row items-center justify-start space-x-2">
        <h3 className="text-base leading-6 font-medium text-zinc-600">
          {label}
        </h3>
        {isNotNullAndNotUndefined(formattedPercentage) && (
          <div
            className={clsx(
              badgeColorScheme,
              "text-sm font-semibold px-1 py-[0.5] rounded"
            )}
          >
            {formattedPercentage}
          </div>
        )}
      </div>
      <div className="text-2xl font-normal tabular-nums text-zinc-900 truncate">
        {displayValue}
      </div>
      <LineChart
        data={labeled}
        categories={[label]}
        index="date"
        className="w-full text-green-200 h-[35px]"
        colors={["#0F4F48"]}
        showLegend={false}
        showYAxis={false}
        showXAxis={false}
        showGridLines={false}
        startEndOnly={true}
        valueFormatter={mode === "dollars" ? formatDollarsWithoutCents : ms}
        showAnimation
      />
    </div>
  );
}

// For our line charts currently, going down is good, so we want to show green
const makePercentDeltaStyles = (
  percentage: number,
  goodDeltaDirection: "positive" | "negative"
) => {
  const deltaDirection = percentage > 0 ? "positive" : "negative";

  const deltaIsGood = deltaDirection === goodDeltaDirection;

  return clsx({
    "text-green-700 bg-green-50": deltaIsGood,
    "text-red-700 bg-red-50": !deltaIsGood,
  });
};
