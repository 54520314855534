import { useTriageDetailAnalyticsQuery } from "@comulate/graphql-types";
import { useMemo } from "react";

export default function useTriageDetailAnalyticsData(
  mode: "Revenue" | "Transactions"
) {
  const { data: triageDetailAnalytics, loading: loadingTriageDetailAnalytics } =
    useTriageDetailAnalyticsQuery();

  const byCarrier = useFormattedData(
    triageDetailAnalytics?.triageAnalyticsByCarrier ?? [],
    mode
  );

  const byStructure = useFormattedData(
    triageDetailAnalytics?.triageAnalyticsByStructure ?? [],
    mode
  );

  // TODO: Maybe get back tag color information from the backend as well
  const byReason = useFormattedData(
    triageDetailAnalytics?.triageAnalyticsByReason ?? [],
    mode
  );

  return {
    loadingTriageDetailAnalytics,
    byCarrier,
    byStructure,
    byReason,
  };
}

const useFormattedData = (
  data: {
    category: string;
    count: number;
    revenue: number;
    tagColor?: string;
  }[],
  mode: "Revenue" | "Transactions"
) => {
  return useMemo(() => formatData(data, mode), [data, mode]);
};

function formatData(
  data: {
    category: string;
    count: number;
    revenue: number;
    tagColor?: string;
  }[],
  mode: "Revenue" | "Transactions"
) {
  return data.map((d) => ({
    label: d.category,
    value: mode === "Revenue" ? d.revenue : d.count,
    tagColor: d.tagColor,
  }));
}
